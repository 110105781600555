export const navigation = [
  {
    title: 'Сделки',
    action: () => {},
    url: '/deals',
  },
  {
    title: 'Клиенты',
    action: () => {},
    url: '/clients',
  },
  {
    title: 'Услуги',
    action: () => {},
    url: '/services',
  },
  {
    title: 'Задачи',
    action: () => {},
    url: '/tasks',
  },
  // {
  //     title: "Календарь",
  //     action: () =>{},
  //     url: "/calendar",
  // },
  {
    title: 'Счета',
    action: () => {},
    url: '/bills',
  },
  // {
  //     title: "Отчеты",
  //     action: () =>{},
  //     url: "/reports",
  // },
  {
    title: 'Настройки',
    action: () => {},
    url: '/settings',
  },
];
